.Graph {
    position: relative;
}

.Graph-calculator {
    width: 325px;
    height: 325px;
    margin: 5px auto;
}

.Graph-button {
    position: absolute;
    bottom: 1px;
    left: 5px;
}

