.HoldingScreen {
    position: absolute;
    z-index: 1;
    top: 55px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    height: 430px;
    width: 95%;
    padding: 0px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.HoldingScreen-text {
    margin-top: 65px;
    font-size: 80px;
}