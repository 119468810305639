.Overlay {
    position: absolute;
    z-index: 1;
    top: 90px;
    left: 30px;
    display: flex;
    align-items: flex-end;
}

.Overlay-icon {
    font-size: 20px;
    color: lightgrey;
    cursor: pointer;
}
