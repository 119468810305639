.NavBar {
    display: flex;
    padding: 15px;
    background-color: #4B0082;
}

.NavBar-link {
    font-size: 30px;    
    font-weight: bold;
    color: white;
    text-decoration: none;
}