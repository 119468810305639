.OptionsTab {
    flex: 1;
    padding: 2px 10px;
    background-color: #FCFCFC;
    font-size: 30px;
    border: 1px solid lightgrey;
    border-radius: 3px;
    cursor: pointer;
}

.OptionsTab-selected {
    background-color: #91D34C;
}