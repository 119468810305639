.Menu-nav {
    margin: 40px auto;
    padding: 40px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    border-radius: 10px;
}

.Menu-link {
    width: 80%;
    max-width: 500px;
    padding: 10px;
    cursor: pointer;
    background-color: #2B7C85;
    font-size: 32px;
    color: white;
    text-align: center;
    text-decoration: none;
    border-radius: 8px;
    border: 2px solid black;
}

.Menu-link:hover, .Menu-link:focus {
    font-weight: bold;
    box-shadow: 0 0 0 5px lightgrey;
}

