.Problem, .Problem-graphing {
    width: 95%;
    height: 500px;
    margin: 0px auto;
    padding: 25px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    font-size: 50px;
    border-radius: 3px;
    box-shadow: 0 0 10px 5px darkgrey;
}

.Problem-graphing {
    padding: 15px 10px;
}

.Problem-main {
    display: flex;
    flex-direction: column;
    gap: 5px;
    display: relative
}

.Problem-main-hide, .Problem-buttons-hide {
    display: none;
}

.Problem-graph {
    position: relative;
}

.Problem-calculator {
    width: 350px;
    height: 350px;
    margin: 5px auto;
}

.Problem-buttons {
    margin: 0px;
    display: flex;    
    flex-direction: row;
    gap: 20px;
}

    
